/* Controls entire page. Highest Layer of App.Index.Js*/
#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* HTML & BODY*/
html,body {
  width: 100%;
  height: 100%;
  font-family:  Arial; /*Fallback*/
  
}